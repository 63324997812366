<template>
  <ui-blur-backdrop>
    <ui-custom-dialog>
      <h3 class="mt-0 mb-4">
        Share Your Computer Sound
      </h3>

      <div class="mb-3 text-center">
        <img
          class="rounded shadow-sm"
          src="https://do.dubbcdn.com/img/zoom-sound-share-tutorial.gif"
          alt="Share Your Computer Sound"
          height="300"
        >
      </div>

      <p class="text-sm">
        Make sure to share your computer sound, optimize for screen sharing, and select stereo sound.
      </p>

      <div class="text-right space-x-2">
        <ui-default-button
          class="w-100px text-base text-gray-500 border-0"
          @click="emit('canceled')"
        >
          Cancel
        </ui-default-button>

        <ui-primary-button
          class="w-100px text-base"
          @click="emit('confirmed')"
        >
          OK
        </ui-primary-button>
      </div>
    </ui-custom-dialog>
  </ui-blur-backdrop>
</template>

<script setup lang="ts">
type ShareComputerSoundDialogEmits = {
  (e: 'confirmed'): void,
  (e: 'canceled'): void
}
const emit = defineEmits<ShareComputerSoundDialogEmits>()
</script>
