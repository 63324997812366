<template>
  <div class="flex flex-col h-screen">
    <share-computer-sound-dialog
      v-if="isShowDialog && !isInMainClient"
      @confirmed="onConfirmed"
      @canceled="onCanceled"
    />

    <app-header-video
      v-if="isInMainClient"
      :title="video?.title"
    />

    <div class="flex-1">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useZoomIsInMainClient } from '~/composables/zoom'
import { useVideo } from '~/composables/videoPage'

const video = useVideo()

const isInMainClient = useZoomIsInMainClient()
const [isShowDialog, toggleIsShowDialog] = useToggle(true)

const onConfirmed = async () => {
  toggleIsShowDialog(false)

  if (isInMainClient.value) {
    return
  }

  await zoomSdk.shareApp({
    action: 'start'
  })

  zoomSdk.onShareApp((event: string) => {
    if (event === 'stop') {
      navigateTo('/')
    }
  })
}

const onCanceled = () => {
  navigateTo('/')
}

</script>
